// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from "react";
import { createRoot } from "react-dom/client";
import MyEmbeddableComponent from "./MyEmbeddableComponent";

window.MyEmbeddableComponent = MyEmbeddableComponent;

if (document.getElementById("embed-container")) {
  const container = document.getElementById("embed-container");
  const root = createRoot(container);
  root.render(<MyEmbeddableComponent />);
}
