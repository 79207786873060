// import React from "react";

// const MyEmbeddableComponent = () => {
//   return (
//     <div
//       style={{
//         padding: "20px",
//         backgroundColor: "#f0f0f0",
//         border: "1px solid #ccc",
//       }}
//     >
//       <h2>My Embeddable Component</h2>
//       <p>This is a simple embeddable React componen</p>
//     </div>
//   );
// };

// export default MyEmbeddableComponent;

"use client";
import { useState, useRef, useEffect } from "react";
// import { FiMessageCircle, FiSend } from "react-icons/fi";
// import { FaTimes } from "react-icons/fa";
import { Spin } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./ChatWidget.module.css";
import {
  MessageOutlined,
  SendOutlined,
  CloseOutlined,
} from "@ant-design/icons";
const MyEmbeddableComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const chatLogRef = useRef(null);

  const toggleChat = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submitter();
    }
  };

  const submitter = async () => {
    try {
      if (input.trim() !== "") {
        setMessages((prev) => [...prev, { text: input, type: "query" }]);
        setInput("");
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: "", type: "response", complete: false },
        ]);
        setLoading(true);

        const threadId = 40;
        // const url = `https://forloopsai.forloops.co/stream_chat?content=${encodeURIComponent(
        //   input
        // )}&thread_id=${encodeURIComponent(threadId)}`;
        const url = ` https://nocodeinstaworld.forloops.co/stream_chat?content=${encodeURIComponent(
          input
        )}&thread_id=${encodeURIComponent(threadId)}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "text/event-stream",
          },
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let done = false;

        while (!done) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          const chunk = decoder.decode(value, { stream: true });
          const chunkData = chunk.replace(/data: /g, "").replace(/\n\n/g, " ");
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            const lastMessageIndex = updatedMessages.length - 1;
            updatedMessages[lastMessageIndex] = {
              ...updatedMessages[lastMessageIndex],
              text: updatedMessages[lastMessageIndex].text + chunkData,
              type: "response",
            };
            return updatedMessages;
          });
        }

        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          const lastMessageIndex = updatedMessages.length - 1;
          if (updatedMessages[lastMessageIndex]) {
            updatedMessages[lastMessageIndex].complete = true;
          }
          return updatedMessages;
        });

        setLoading(false);
      }
    } catch (error) {
      console.error("Error while sending message", error.message);
      setLoading(false);
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages.pop();
        return updatedMessages;
      });
    }
  };

  useEffect(() => {
    if (chatLogRef.current) {
      chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <>
      <div>{/* <h1>Embeded chat widget</h1> */}</div>
      <div className="position-relative">
        <div className={styles.chatWidget}>
          {!isOpen && (
            <div
              className={`${styles.chatButton} btn btn-primary`}
              onClick={toggleChat}
              style={{
                fontSize: "large",
              }}
            >
              <MessageOutlined size={24} /> Chat Now
            </div>
          )}
          {isOpen && (
            <div className={`d-flex flex-column ${styles.chatWindow}`}>
              <div className="d-flex justify-content-between align-items-center p-3 bg-primary text-white rounded-top">
                <p className="mb-0 text-white">Live Support</p>
                <button
                  className="btn btn-primary text-white"
                  onClick={toggleChat}
                >
                  <CloseOutlined size={24} />
                </button>
              </div>
              <div
                ref={chatLogRef}
                className="p-3 chatheight "
                style={{
                  height: "350px",
                  overflowY: "auto",
                  backgroundColor: "#f8f9fa",
                  scrollBehavior: "smooth",
                  display: "block",
                }}
              >
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`d-flex align-items-start mb-2 ${
                      message.type === "query"
                        ? "justify-content-end"
                        : "justify-content-start"
                    }`}
                  >
                    <div
                      className={`p-2 rounded ${
                        message.type === "query"
                          ? "bg-primary text-white"
                          : "bg-light text-dark"
                      }`}
                    >
                      {message.text}
                    </div>
                  </div>
                ))}
                {loading && (
                  <div className="d-flex justify-content-center align-items-center my-3">
                    <Spin size="small" />
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center p-3 bg-primary">
                <input
                  type="text"
                  id="chat-input"
                  className="form-control   rounded-pill me-2"
                  placeholder="Send a message"
                  value={input}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyPress}
                  style={{ borderColor: "transparent", fontSize: "18px" }}
                />
                <button
                  onClick={submitter}
                  className="btn btn-light rounded-pill"
                >
                  <SendOutlined size={20} color="#007bff" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MyEmbeddableComponent;
